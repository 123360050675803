/*************************FORMS, LEGENDS & LABELS ***************************** */

.form {
  margin: 0;
}

fieldset,
.fieldset {
  margin: 0 0 1em 0;
  padding: 0;
  display: block;
  border: none;
}

legend,
.legend {
  @include text-light;
  font-size: 0.875em;
  display: block;
  padding: 0;
  margin: 0 0 0.5em 0;
  @include mq-phone {
    @include text-medium;
  }
}

.label {
  @include text-medium;
  font-size: 0.8125rem;
  display: inline-block;
  margin-right: 5px;
  text-align: right;
  padding: 3px 0;
  &--radio,
  &--checkbox {
    height: 26px;
    display: inline-block;
    vertical-align: bottom;
  }
  @include mq-phone {
    text-align: left;
    @include text-regular;
    font-size: 0.75rem;
  }
}

/***************************** ERROR & WARNING MESSAGES ************************************* */

/*  inputs error messages  */
.input-error-message {
  border-bottom: 5px solid transparent;
  color: $color-red;
  font-size: 0.75rem;
  @include text-regular;
  text-align: end;
  display: block;
}

.input-warning-message {
  font-size: 0.75rem;
  @include text-regular;
  font-style: italic;
  display: block;
  text-align: right;
  &:before {
    content: '\f06a';
    color: $color-yellow-1;
    @include fontawesome;
    margin-right: 0.25em;
  }
}

.input-warning-block-message {
  font-size: 0.75rem;
  @include text-regular;
  font-style: italic;
  display: block;
  padding-top: 10px;
}

/***************************** INPUTS ************************************* */

/*  placeholder text */
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
input:-moz-placeholder,
::placeholder {
  color: $color-gray-2;
  @include text-medium;
}
:-ms-input-placeholder {
  color: $color-gray-2 !important;
}

/*  inputs  */

// /TODO: move this to variables once I'm done with italic
$height-form-elements: 22px;

.input {
  @include text-regular;
  border: 1px solid $color-gray-3;
  border-radius: $border-radius-forms;
  font-size: 13px;
  padding: 2px 4px;
  &--error {
    border-color: $color-red;
  }
  &:focus {
    border-radius: $border-radius-forms;
    outline-color: $color-yellow-1;
  }
  &--textarea {
    width: 100%;
    height: 100px;
    resize: none;
  }
  &--textarea-new-case {
    width: 100%;
    height: 56px;
    resize: none;
    @include mq-phone {
      height: 100%;
    }
  }
  &--select {
    //   	-webkit-appearance: none;
    //   	-moz-appearance: none;
    height: $height-form-elements;
  }
  &--text {
    height: $height-form-elements;
  }
  &[type='text'] {
    height: $height-form-elements;
  }
  &[type='password'] {
    height: $height-form-elements;
  }
  &[type='number'] {
    text-align: right;
    height: $height-form-elements;
    max-width: 144px;
  }
  &[type='date'] {
    text-align: left;
    height: $height-form-elements;
  }

  &--small {
    width: 40px;
  }
  &--regular {
    width: 50px;
  }
  &--medium {
    width: 80px;
  }
  &--medium-age {
    width: 160px;
  }
  &--medium-sex {
    width: 94px;
  }
  &--medium-drugs {
    width: 75px;
  }
  &--medium-race {
    width: 160px;
  }
  &--large {
    width: 295px;
  }
  &--full {
    width: 100%;
  }
  &--border-small {
    border-bottom: 5px solid transparent;
  }
  &--border-medium {
    border-bottom: 10px solid transparent;
  }
  &--border-large {
    border-bottom: 20px solid transparent;
  }
  &--85 {
    width: 85px;
  }
  &--90 {
    width: 90px;
  }
  &--120 {
    width: 120px;
  }
  &--200 {
    width: 240px;
  }
  &--240 {
    width: 240px;
  }
  @include mq-phone {
    width: 100%;
  }
  &--radio {
    height: 26px;
    display: inline-block;
    vertical-align: bottom;
    @include mq-phone {
      width: auto;
    }
  }
  &--checkbox {
    height: 26px;
    display: inline-block;
    vertical-align: bottom;
    @include mq-phone {
      width: auto;
    }
  }
}

// adjust input date font size
input[type="date"] {
  font-size: 12px;
}

// remove the 'date picker'
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

// remove the 'input spinners' at the end of number pickers.
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//* INPUT WRAPPER - usually the label around the input itself */
.input-error-wrapper {
  display: flex;
  flex-direction: column;
  @include mq-phone {
    display: block;
  }
}

.input-label,
.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  &--full {
    width: 100%;
  }
  @include mq-phone {
    justify-content: flex-start;
    flex-direction: column;
    .label {
      text-align: left;
      margin-bottom: 4px;
    }
  }

  &--left {
    display: table;
    justify-content: flex-start;
    .input-label__text {
      text-align: left;
    }
    .label {
      text-align: left;
    }
    @include mq-phone {
      display: flex;
    }
  }
  &--spread {
    justify-content: space-between;
    .label {
      text-align: left;
    }
  }
  &--new-case,
  &--right {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @include mq-phone {
      flex-direction: column;
    }
  }
  &--risk-other {
    margin: 3px 8px;
  }
}

/* Combobox - the matching segments of text */
[data-user-value] {
  font-weight: bold !important;
}

/* Special list for errors at the bottom of comment input */

.comments-word-warnings-list {
  font-size: 0.75rem;
  text-align: right;
  list-style: none;
  padding: 0;
  line-height: 18px;
}

.input-error-message + .comments-word-warnings-list {
  margin-top: -2px;
}

// .case-form .fieldset:nth-child(2) {
//   margin-bottom: 0;
// }

/*********************** SELECT OPTION LIST ************************ */

// .select {
// 	height: 22px;
// 	border: 1px solid pink;
// }

/*********************** CHECKBOXES & RADIO BUTTONS ************************ */
// .input-wrapper  {
// 	&--radio {
// 		.input-wrapper__text {margin-left: 6px;}
// 	}
// 	&--radio-row{
// 		width: 100%;
// 		max-width: 400px;
// 		padding: 8px 5px;
// 		display: flex;
// 		justify-content: space-between;
// 	}
// 	&--radio-row-zebra{
// 		background: $color-gray-4;
// 	}
// 	&--checkbox{}
// }

/***************************** BUTTONS ************************************* */

.case-form__save-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.button {
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
  &:disabled:hover {
    cursor: default;
  }
  outline-color: $color-yellow-1;
}
.button--csv {
  color: $color-black;
  text-decoration: none;
}

.button--primary,
.button--secondary,
.button--warning {
  font-size: 14px;
  min-width: 140px;
  padding: 10px 16px;
  border-radius: $border-radius;
  @include text-regular;
  outline-color: $color-blue-1;
  &:disabled,
  &:disabled:hover {
    color: $color-gray-1;
    background: $color-gray-4;
    border-color: $color-gray-3;
  }
}

.button--small {
  min-width: 105px;
  padding: 4px 16px;
}

.button--narrow {
  min-width: 70px;
}

.button--primary {
  background: $color-yellow-1;
  border: 1px solid $color-yellow-1;
  &:hover {
    color: $color-white;
    background: $color-blue-3;
    border-color: $color-blue-3;
  }
}

.button--warning {
  background: $color-red;
  border: 1px solid $color-red;
  color: $color-white;
  &:hover {
    color: $color-black;
    background: $color-yellow-1;
    border-color: $color-yellow-1;
  }
}

// small primary button is dif color to look better on page
.button--primary.button--small {
  color: $color-white;
  background: $color-blue-3;
  border: 1px solid $color-blue-3;
  outline-color: $color-yellow-1;
  &:hover {
    color: $color-gray-1;
    background: $color-yellow-1;
    border-color: $color-yellow-1;
  }
  &:disabled,
  &:disabled:hover {
    color: $color-gray-1;
    background: $color-gray-4;
    border-color: $color-gray-3;
  }
}

.button--secondary {
  background: $color-white;
  border: 1px solid $color-blue-3;
  color: $color-blue-3;
  &:hover {
    color: $color-blue-1;
    border-color: $color-blue-1;
  }
}

.button--secondary-warning {
  background: $color-white;
  border: 1px solid $color-red;
  color: $color-red;
  &:hover {
    color: $color-white;
    background: $color-red;
    border-color: $color-red;
  }
}

.button--secondary-success {
  background: $color-white;
  border: 1px solid $color-green;
  color: $color-green;
  &:hover {
    color: $color-white;
    background: $color-green;
    border-color: $color-green;
  }
}

/*  Round icon buttons  */
.button--circle {
  display: inline-block;
  height: 30px;
  line-height: 28px;
  text-align: center;
  width: 30px;
  border-radius: 100%;
  color: $color-white;
  background: $color-blue-2;
  border: none;
  margin: 0;
  font-size: 16px;
  &:hover {
    background-color: $color-blue-1;
  }
  &:disabled {
    background-color: $color-gray-2;
  }
  &:disabled:hover {
    background-color: $color-gray-2;
  }
}

.button--tooltip {
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  background: $color-white;
  border: 1px solid $color-gray-2;
  color: $color-gray-2;
  font-size: 18px;
  &-case {
    top: -4px;
    left: 70px;
  }
  &:hover {
    color: $color-white;
    border-color: $color-blue-3;
    background: $color-blue-3;
  }
}

.button--tooltip__showing {
  display: none;
}
.button--tooltip-show {
  background: $color-yellow-3;
  border: 1px solid $color-yellow-1;
  color: $color-gray-1;
  &:hover {
    background: $color-yellow-1;
    border: 1px solid $color-yellow-1;
    color: $color-gray-1;
  }
  .button--tooltip__showing {
    display: inline;
  }
  .button--tooltip__not-showing {
    display: none;
  }
}

// tab buttons
.button--tab {
  @include text-regular;
  color: $color-gray-1;
  border: none;
  background: $color-gray-4;
  font-size: 12px;
  padding: 12px 32px;
  border-radius: $border-radius $border-radius 0 0;
  &:hover {
    background: $color-yellow-1;
  }
  @include mq-phone {
    padding: 6px 8px;
  }
  &[aria-selected='true'] {
    padding: 13px 32px;
    background: $color-white;
    @include text-bold();
    position: relative;
    @include mq-phone {
      padding: 7px 8px;
    }
    &:after {
      content: ' ';
      display: block;
      height: 5px;
      width: 50%;
      background: $color-yellow-1;
      position: absolute;
      left: 0px;
      bottom: -5px;
    }
  }
}

// the sole fake "drop down" button in the header
.button--header-drop-down {
  padding: 0;
  margin: 0;
  background: transparent;
  color: $color-white;
  border: none;
  @include text-thin;
  border-radius: 1px;
  &:hover {
    background: $color-blue-1;
    box-shadow: 0px 0px 0px 6px $color-blue-1;
  }
}

.button--toaster-close {
  border: none;
  color: $color-gray-2;
  background: transparent;
  text-align: center;
  font-size: 20px;
  padding: 4px;
  border-radius: $border-radius;
  &:hover {
    color: $color-white;
    background: $color-blue-3;
  }
}

// the square buttons to add/remove saved search
.button--square {
  padding: 0;
  background: transparent;
  color: $color-blue-3;
  border: 1px solid $color-blue-3;
  font-size: 0.875rem;
  border-radius: $border-radius;
  height: 22px;
  //line-height: 22px;
  width: 22px;
  text-align: center;
  margin: 0 0.33rem;
  &:hover {
    color: $color-blue-1;
    border-color: $color-blue-1;
  }
  &:disabled {
    color: $color-gray-2;
    border-color: $color-gray-2;
  }
  &:disabled:hover {
    color: $color-gray-2;
    border-color: $color-gray-2;
  }
  @include mq-phone {
    height: auto;
    width: auto;
    padding: 0.75em;
    margin: 0.5em;
  }
}

// the sort buttons at the top of tables
.button--table-sort {
  background: transparent;
  padding: 0;
  border: 0;
  @include text-bold();
  font-size: 12px;
  position: relative;
  padding-left: 1em;
  // &:before {
  //   @include fontawesome;
  //   content: '\F0DC';
  //   display: block;
  //   height: 12px;
  //   width: 12px;
  //   position: absolute;
  //   left: -4px;
  //   bottom: 1px;
  // }
  // &:hover {
  //   background: transparent;
  //   color: $color-blue-2;
  // }
  // &-ascending:before {
  //   content: '\F0DE';
  // }
  // &-descending:before {
  //   content: '\F0DD';
  // }
}

.button-sort-icon {
  background: transparent;
  display: inline-block;
  height: 12px;
  width: 12px;
  position: relative;
  vertical-align: middle;
  margin-right: 2px;
  &:before {
    background: transparent;
    @include fontawesome;
    content: '\F0DC';
    display: block;
    height: 12px;
    width: 12px;
    position: absolute;
    left: 0px;
    top: 0;
  }
  &--ascending:before {
    @include fontawesome;
    content: '\F0DE';
    display: block;
    height: 12px;
    width: 12px;
    position: absolute;
    left: 0px;
    top: 0;
  }
  &--descending:before {
    @include fontawesome;
    content: '\F0DD';
    display: block;
    height: 12px;
    width: 12px;
    position: absolute;
    left: 0px;
    top: 0;
  }
}

.button-victim-id {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  min-width: 0;
}
/***************************** DATALISTS ************************************* */

/* datalist arrow */
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
