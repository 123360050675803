.footer {
  @include text-regular;
  background-color: $color-blue-3;
  color: $color-white;
  font-size: 12px;
  line-height: 18px;
  max-width: 1024px;
  width: 100%;
  height: auto;
  margin-top: auto;
  padding: $margin-vert-desktop 0 $margin-horz-desktop 0;
  box-shadow: 1000px 0px 0px 0px $color-blue-3,
    -1000px 0px 0px 0px $color-blue-3;
  display: flex;
  justify-content: space-between;
  > div:nth-child(2) {
    text-align: right;
    align-self: flex-end;
  }
  @include mq-phone {
    padding: $margin-vert-phone $margin-horz-phone;
    flex-direction: column;
    > div:nth-child(2) {
      text-align: left;
      align-self: flex-start;
    }
  }
}
