/************************* DASHBOARD SEARCH & TABLE ***************************** */

.dashboard-search {
  margin-bottom: 1.5em;
  &__top {
    margin-bottom: 0.5em;
  }
  &__top-child {
    &:nth-child(2) {
      margin: 0 auto;
      @include mq-phone {
        margin: 0;
      }
    }
    &:nth-child(3) {
      margin-left: auto;
      @include mq-phone {
        margin: 0;
      }
    }
  }
  &__top-child-legend {
    margin-bottom: 0;
  }

  &__bottom {
    background: $color-gray-5;
    border-radius: $border-radius;
    max-width: 690px;
    margin-left: auto;
    @include mq-phone {
      padding: 0.5rem;
    }
  }
  &__bottom-child {
    padding: 0.5rem;
    @include mq-phone {
      padding: 0;
      width: 100%;
    }
    // saved searches
    &-saved-searches {
      order: 0;
      padding-right: 0;
      margin-bottom: 0;
    }
    // buttons to save/delete searches
    &-save-delete-buttons {
      order: 1;
      padding-left: 0;
    }
    // main search button
    &-search-button {
      order: 4;
      padding: 0;
      margin-left: auto;
      @include mq-phone {
        margin: 0;
      }
    }
    // main search button
    &-reset-button {
      order: 3;
      padding: 0;
      margin-left: auto;
      @include mq-phone {
        margin: 0;
      }
    }
  }
  &__bottom-divider {
    order: 2;
    width: 2px;
    height: 42px;
    background: $color-white;
    margin: 0 auto;
    @include mq-phone {
      display: none;
    }
  }
  &__search-save-button {
    @include mq-phone {
      display: none;
    }
  }
  &__search-submit-button {
    @include mq-phone {
      margin-top: 1em;
      width: 100%;
    }
  }
  &__padded-label.label {
    @include mq-phone {
      margin-left: 0;
    }
  }
  &__from-date-label {
    width: 256px;
  }
  &__date-input {
    width: 140px;
    max-width: 140px;
  }
  &-number-found {
    font-size: 0.8125rem;
    @include text-light;
    > b {
      margin-right: 0.25em;
    }
    &__divider {
      margin: 0 2em;
      color: $color-gray-2;
    }
  }
}

.dashboard-table-container {
  max-height: 500px;
  overflow-x: scroll;
  margin-top: 0.5rem;
  position: relative;
  @include mq-phone {
    max-height: auto;
    overflow-x: auto;
  }
}
.dashboard-table {
  margin-top: 0em;
  &__product-cell {
    max-width: 100px;
  }
  thead th {
    position: sticky;
    top: 0;
    background: $color-gray-4;
  }
  &-no-results {
    font-size: 1.25em;
    color: $color-gray-2;
  }
  @include mq-phone {
    thead th:nth-child(1),
    tbody td:nth-child(1),
    thead th:nth-child(2),
    tbody td:nth-child(2),
    thead th:nth-child(5),
    tbody td:nth-child(5),
    thead th:nth-child(6),
    tbody td:nth-child(6),
    thead th:nth-child(7),
    tbody td:nth-child(7),
    thead th:nth-child(8),
    tbody td:nth-child(8),
    thead th:nth-child(9),
    tbody td:nth-child(9) {
      display: none;
    }
  }
}
.dashboard-search-show-details {
  @include mq-phone {
    display: none;
  }
}

.dashboard-search-actions {
  position: absolute;
  top: 184px;
  right: $margin-horz-desktop;
}

.dashboard-delete-search-list {
  list-style: none;
  padding-left: 1em;
}

.dashboard-table__attention-list {
  list-style: none;
  padding: 0;
  display: inline;
  font-style: italic;
  margin-left: 0.25em;
  color: $color-red;
  > li {
    display: inline;
    &:after {
      content: ',';
      margin-right: 0.5em;
    }
  }
  > li:last-child:after {
    display: none;
  }
}
