// ************************* FONT FAMILIES *******************
// done this way so that if fonts don't load the will still look somewhat okay

@mixin text-regular {
  font-family: $text-regular;
  // font-weight: 400;
}

@mixin text-thin {
  font-family: $text-thin;
  // font-weight: 100;
}

@mixin text-medium {
  font-family: $text-medium;
  // font-weight: 500;
}

@mixin text-light {
  font-family: $text-light;
  // font-weight: 200;
}

@mixin text-bold {
  font-family: $text-bold;
  // font-weight: 700;
}

@mixin text-no-results {
  @include text-regular();
  text-align: center;
  font-size: 1.2rem;
  color: $color-gray-2;
}

@mixin fontawesome {
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: 'FontAwesome', sans-serif;
}

// use this to de-ellipsis overflow an element
@mixin ut-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

@mixin ut-nowrap {
  white-space: nowrap;
}

@mixin zebra {
  background: $color-gray-5;
}

// ************************* Loading Spinner *******************
@mixin loading-spinner {
  text-transform: uppercase;
  position: relative;
  padding: 0 40px;
  margin: $margin-horz-desktop auto 16px auto;
  display: inline-block;
  @include text-medium;
  color: $color-blue-2;
  &:before {
    content: '\f110';
    @include fontawesome;
    font-size: 1.75rem;
    position: absolute;
    top: -2px;
    left: 0px;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    color: $color-blue-2;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

// ************************* MEDIA QUERIES *******************
@mixin mq-phone {
  @media (min-width: 0 ) and (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin mq-tablet {
  @media (min-width: #{$phone-width + 1}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mq-phone-and-tablet {
  @media (min-width: 0 ) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mq-desktop {
  @media (min-width: #{$tablet-width + 1}) {
    @content;
  }
}

// ************************* UTILITY MIXINS *******************
@mixin ut-only-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
