/***************************** UTILITY STYLES ************************************* */

.ut-loading-spinner {
  @include loading-spinner;
}

// alignment
.ut-align {
  &-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
    &[type='number'] {
      text-align: left;
    }
  }
}

/****************** FONTS ***************************/

.ut-font-regular {
  @include text-regular;
}

.ut-font-bold {
  @include text-bold;
}

/****************** Width ***************************/
.ut-width {
  &-90 {
    width: 90px;
  }
  &-100-percent {
    width: 100%;
  }
}

// max width
.ut-max-width {
  &-100 {
    max-width: 100px;
  }
  &-320 {
    max-width: 320px;
  }
  &-400 {
    max-width: 400px;
  }
  &-445 {
    max-width: 445px;
  }
  &-500 {
    max-width: 500px;
  }
  &-600 {
    max-width: 600px;
  }
  &-700 {
    max-width: 700px;
  }
  &-800 {
    max-width: 800px;
  }
  &-900 {
    max-width: 900px;
  }
  &-1024 {
    max-width: 1024px;
  }
  @include mq-phone {
    max-width: none;
  }
}

.ut-indent-1em {
  margin-left: 1em;
  @include mq-phone {
    margin-left: 0em;
  }
}

// indentation used mostly on forms
.ut-indent-tiny {
  min-width: 35px;
  width: 35px;
}

.ut-indent-small {
  min-width: 50px;
  width: 50px;
}

.ut-indent-medium {
  min-width: 80px;
  width: 80px;
}
.ut-indent-large {
  min-width: 115px;
  width: 115px;
}

.ut-indent-large-drugs {
  min-width: 125px;
  width: 125px;
}

.ut-indent-xlarge {
  min-width: 160px;
  width: 160px;
}

.ut-indent-full {
  min-width: 100%;
  width: 100%;
}

/****************** margins ***************************/

.ut-margin {
  &-bottom-half-em {
    margin-bottom: 0.5em;
  }
  &-bottom-1-em {
    margin-bottom: 1em;
  }
  &-top-0 {
    margin-top: 0;
  }
  &-bottom-0 {
    margin-bottom: 0;
  }
  &-top-1-em {
    margin-top: 1em;
  }
  &-top-auto {
    margin-top: auto;
  }
  &-right-1-em {
    margin-right: 1em;
  }
  &-right-half-em {
    margin-right: 0.5em;
  }
  &-left-1-em {
    margin-left: 1em;
  }
}

/****************** padding ***************************/
.ut-padding {
  &-table {
    padding: 12px 8px;
  }
  &-table--compact {
    padding: 5px 8px;
  }
  &-right-1em {
    padding-right: 1em;
  }
  &-right-half-em {
    padding-right: 0.5em;
  }
  &-top-0 {
    padding-top: 0;
  }
}

/****************** WORD WRAPPING & LIKE ***************************/
.ut-ellipsis-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

// use this to de-ellipsis overflow an element
.ut-word-wrap {
  @include ut-word-wrap;
}

.ut-nowrap {
  @include ut-nowrap;
  @include mq-phone {
    @include ut-word-wrap;
  }
}

.ut-relative,
.ut-position-relative {
  position: relative;
}

/****************** DISPLAY & VISIBILITY***************************/
.ut-display {
  &-none {
    display: none;
  }
  &-inline {
    display: inline;
  }
  &-inline-block {
    display: inline-block;
  }
  &-block {
    display: block;
  }
}

.ut-invisible {
  visibility: hidden;
}

/****************** COLOR CHANGES ***************************/

.ut-color {
  &-red {
    color: $color-red;
  }
  &-yellow {
    color: $color-yellow-1;
  }
  &-green {
    color: $color-green;
  }
  &-gray {
    color: $color-gray-2;
  }
  &-black {
    color: $color-gray-1;
  }
}
.ut-bg-zebra {
  @include zebra;
}

/*********** for elements only on screen reader *************/

.ut-only-sr {
  @include ut-only-sr;
}

/********************* media query stuff ******************/
.ut-not-phone {
  @include mq-phone {
    display: none;
  }
}

.ut-not-tablet {
  @include mq-tablet {
    display: none;
  }
}

.ut-not-desktop {
  @include mq-desktop {
    display: none;
  }
}

.ut-only-phone {
  @include mq-tablet {
    display: none;
  }

  @include mq-desktop {
    display: none;
  }
}

.ut-only-tablet {
  @include mq-phone {
    display: none;
  }

  @include mq-desktop {
    display: none;
  }
}

.ut-only-desktop {
  @include mq-phone {
    display: none;
  }

  @include mq-tablet {
    display: none;
  }
}

.ut-test {
  background: pink;
}
