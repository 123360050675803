// ********************* Variables *********************

// ********************* PATHS *********************
$path-font: 'assets/fonts/';
$path-img: 'assets/images/';

/***************************** FONT IMPORTS ************************************* */
@font-face {
  font-family: 'roboto-regular';
  src: url($path-font + 'Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-medium';
  font-weight: normal;
  src: url($path-font + 'Roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-thin';
  font-weight: normal;
  src: url($path-font + 'Roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-light';
  font-weight: normal;
  src: url($path-font + 'Roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-bold';
  src: url($path-font + 'Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome';
  src: url($path-font + 'FontAwesome/fontawesome-webfont.ttf')
    format('truetype');
}

// ********************* FONTS *********************
$text-thin: 'roboto-thin', Roboto, sans-serif;
$text-light: 'roboto-light', Roboto, sans-serif;
$text-regular: 'roboto-regular', Roboto, sans-serif;
$text-medium: 'roboto-medium', Roboto, sans-serif;
$text-bold: 'roboto-bold', Roboto, sans-serif;

// ********************* COLORS *********************
// lowest = darkest hue

$color-white: #ffffff;
$color-black: #000000;

// Grays
$color-gray-1: #333333; // Standard text color & color for all disabled text
$color-gray-2: #767676; // Lightest allowable text; input placeholder text and helper text.
$color-gray-3: #c4c4c4; // Form borders
$color-gray-4: #ebebeb; // Disabled elements backgrounds
$color-gray-5: #f6f7fb; // Page background & table zebra stripes
$color-gray-6: #e0e6f2; // Alternative lighter color.

// Blues
$color-blue-1: #101168; // Darkerst blue
$color-blue-2: #215295; // medium blue
$color-blue-3: #286fa8; // lighest blue for header, footer, most headers, buttons, and links in text.

// Other colors
$color-red: #c7270f; // color for warnings, errors, or message notifications
$color-green: #039d12; // color for finished / affirmative messages
$color-yellow-1: #f1b33c; // active/rollover buttons or highlight location
$color-yellow-2: #f6f0ea; // Highlight for selected rows in tables (accounting & ERVs pages)
$color-yellow-3: #fae0aa; // tooltip bg color

// the BG used for all modal overlays and the like
$color-bg-modal: rgba(0, 0, 0, 0.75);

// color for 508 focus around elements
$color-focus: pink; // undecided. TODO: pick a color

$color-divider: rgba(
  255,
  255,
  255,
  0.25
); //the semi-translucent dividers used in header & footer

// ********************* BOX SHADOWS *********************
$box-shadow-color-focus: 0px 0px 5px $color-focus;

// ********************* BORDER RADIUS *********************
$border-radius: 4px;
$border-radius-forms: 0px;

// ********************* STANDARD MARGINS *********************

$margin-horz-desktop: 32px;
$margin-horz-phone: 12px;

$margin-vert-desktop: 24px;
$margin-vert-phone: 12px;

// ********************* VIEWPORTS FOR MEDIA QUERIES *********************
$phone-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$giant-desktop-width: 1600px;
