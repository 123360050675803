/************************* TOASTER ***************************** */

// set toaster--visible to show
// toaster defaults to green sucess color & icon. add --error to switch to red color & icon
.toaster {
  font-size: 1rem;
  overflow: hidden;
  width: 500px;
  max-height: 120px;
  background: $color-white;
  border-bottom-color: $color-green;
  border-bottom-style: solid;
  border-bottom-width: 6px;
  padding: 1em 1.5em;
  display: flex;
  position: fixed;
  top: -121px;
  left: 50%;
  margin-left: -250px;
  color: $color-gray-1;
  transition: top 0.8s;
  @include mq-phone {
    width: 100%;
  }
  &__icon {
    font-size: 22px;
    width: 22px;
  }
  &__icon-check {
    color: green;
  }
  &__icon-warning {
    color: $color-red;
    display: none;
  }
  &__message {
    @include text-regular;
    font-size: 0.8125em;
    text-align: left;
    padding-left: 1.5rem;
    padding-top: 4px;
  }
  &__close {
    margin-left: auto;
  }
  &--visible {
    top: 0px;
  }
  &--warning {
    border-bottom-color: $color-red;
    .toaster__icon-check {
      display: none;
    }
    .toaster__icon-warning {
      display: inline;
    }
  }
}
