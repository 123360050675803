/************************* Accounting & ERVs layouts ***************************** */

.accounting-flex-container {
  width: 100%;
  &__left {
    min-width: 250px;
    @include mq-phone {
      min-width: 0px;
    }
  }
  &__right {
    width: calc(100% - 250px);
    @include mq-phone {
      width: auto;
    }
  }
  &__center {
    min-width: 400px;
    @include mq-phone {
      width: auto;
    }
  }
}

.accounting-select-table {
  .table__row:nth-child(even) {
    @include zebra;
  }
  @include mq-phone {
    margin-bottom: 1em;
  }
}

.accounting-results-container {
  padding: 0.5em;
  border: 1px solid $color-yellow-1;
  border-radius: $border-radius;
  margin-left: 24px;
  &__bottom {
    padding: 1em 0 0.5em 0;
    margin: auto auto 0 auto;
  }
  &__confirm {
    display: flex;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    margin: 15px 25px;
    
    label {
      display: inline-flex;
    }
    span {
      margin: 10px 0 0 5px;
    }
  }
  .table__row:nth-child(even) {
    @include zebra;
  }
  @include mq-phone {
    padding: 0;
    margin-left: 0;
  }
}

.accounting-results-table {
  @include mq-phone {
    margin-bottom: 1em;
  }
  &--left {
    margin-right: 1em;
  }
  &--right {
    margin-right: auto;
  }
}
