.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-bg-modal;
}

.modal {
  position: absolute;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  top: 40px;
  background: $color-white;
  overflow: auto;
  border-radius: 0 0 $border-radius $border-radius;
  outline: none;
  padding: 0;
  &__header {
    background: $color-blue-2;
    height: 40px;
    text-align: right;
  }
  &__header-button {
    height: 40px;
    width: 40px;
    color: $color-white;
    background: $color-blue-1;
    border-radius: 0;
    border: none;
    font-size: 1.25em;
    &:hover {
      color: $color-black;
      background: $color-yellow-1;
    }
  }
  &__h2 {
    margin: 0;
    padding: $margin-horz-desktop $margin-horz-desktop 16px $margin-horz-desktop;
  }
  &__body {
    padding: 0 $margin-horz-desktop $margin-horz-desktop $margin-horz-desktop;
    line-height: 1.25em;
  }
  &__body--delete {
    font-size: 0.875em;
    @include text-regular;
    font-style: italic;
    padding: 10px 0;
  }
  &__buttons {
    padding: 0 $margin-horz-desktop $margin-horz-desktop $margin-horz-desktop;
  }
}

.modal--loading {
  width: 400px;
  margin-left: -200px;
  text-align: center;
  border-radius: $border-radius;
  padding: 1em 0;
  h2 {
    @include loading-spinner;
  }
  .modal__header {
    display: none;
  }

  .modal__buttons {
    display: none;
  }
}
