/************************* Messages ***************************** */

.messages-table {
  &__read-col {
    width: 1em;
  }
  &__row {
    &:nth-child(even) {
      background: #f6f7fb;
    }
    > td {
      vertical-align: top;
      text-align: left;
      @include text-bold();
    }
  }
  .messages-table__is-read {
    display: none;
  }
  &__row--is-read {
    .messages-table__is-read {
      display: inline-block;
    }
    .messages-table__not-read {
      display: none;
    }
    > td {
      @include text-regular();
    }
  }
  &__none {
    > td {
      @include text-no-results();
    }
  }

  @include mq-phone {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    .messages-table__row {
      text-align: left;
    }
    .messages-table__cell-is-read {
      display: inline-block;
      width: 16px;
      padding-bottom: 0;
    }
    .messages-table__cell-date {
      display: inline-block;
      padding-bottom: 0;
    }
    .messages-table__row--is-read .messages-table__cell-is-read {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }
}
