.error-messsages {
  &__list {
    list-style: none;
    padding: 0;
    font-size: 0.875rem;
    > li {
      margin-bottom: 1.5em;
    }
  }
  &__date {
    margin-right: 0.5em;
    @include text-regular;
  }
  &__type {
    @include text-bold;
    margin-bottom: 0.25em;
  }
  &__email {
    @include text-regular;
    margin-bottom: 0.25em;
  }
  &__message {
    @include text-regular;
    margin-bottom: 0.5em;
  }
  &__reply {
    text-align: left;
  }
  &__button {
    min-width: 90px;
    > span {
      margin-right: 6px;
    }
  }
}
section.error-messages {
  max-width: 400px;
  margin-top: -36px;
  padding-top: 1.5em;
  border-top: 1px solid $color-gray-3;
}
