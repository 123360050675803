/************************* combobox ***************************** */
// these styles override the styles for the third-party combo box

/* COMBOBOX WIP */
[data-reach-combobox-list] {
  max-height: 250px;
  overflow-y: auto;
}

ul[role='listbox'] {
  > li {
    @include text-medium;
    font-size: 13px;
    color: $color-gray-1;

    &:hover {
      color: $color-white;
      background: $color-blue-3;
    }
    > span {
      font-weight: normal;
    }
  }
}
