.tooltip {
  &__wrapper {
    position: relative;
    &--full {
      width: 100%;
    }
  }
  &__content {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    position: absolute;
    top: 2px;
    right: -22px;
    height: 18px;
    width: 18px;
    overflow: hidden;
    border-radius: 100%;
    color: $color-gray-1;
    background: $color-yellow-3;
    &:after {
      content: 'i';
      text-align: center;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      background: $color-yellow-3;
      height: 18px;
      width: 18px;
      border-radius: 100%;
    }
  }
}

input:focus + .tooltip__content,
textarea:focus + .tooltip__content {
  top: -8px;
  right: -228px;
  height: auto;
  width: auto;
  width: 220px;
  text-align: left;
  padding: 0.875em;
  border-radius: $border-radius;
  z-index: 1000;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.33);
  overflow: visible;
  font-family: $text-regular;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.25em;
  &--large {
    width: 340px;
    right: -348px;
  }
  &:after {
    display: none;
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: -8px;
    top: -1px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-right: 16px solid $color-yellow-3;
    border-bottom: 20px solid transparent;
  }
}

input:focus + .tooltip__content--pdf-link,
.tooltip__content--pdf-link {
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
  background-color: transparent;
  line-height: 18px;
  position: absolute;
  top: 2px;
  right: -22px;
  height: 18px;
  width: 18px;
  padding: 0;
  box-shadow: none;
  overflow: hidden;
  border-radius: 100%;
  color: $color-gray-1;
  background: $color-yellow-3;
  a {
    text-align: center;
    display: block;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    background: $color-yellow-3;
    height: 18px;
    width: 18px;
    border-radius: 100%;
    text-decoration: none;
    font-size: 12px;
    color: $color-gray-1;
    &:hover,
    &:focus {
      background-color: $color-blue-3;
      color: white;
      border: 1px solid $color-blue-3;
    }
  }
}

.tooltips--hidden .tooltip__content {
  display: none;
}

// format content within tool tips content div
.tooltip__content {
  ul {
    margin-top: 0;
    padding-left: 1.5em;
  }
  p + ul {
    margin-top: -0.75em;
  }
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }
}
