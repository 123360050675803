.form-ade__four-drugs {
  justify-content: flex-start;
  padding-top: 0.5em;

  .flex-child {
    border-left-width: 0px;
    width: auto;
    .input-wrapper {
      height: 52px;
      padding: 0.5rem 1rem;
      margin: 0;
      &:nth-child(even) {
        background: $color-gray-5;
      }
    }
  }
  legend {
    @include text-bold;
    font-size: 13px;
    margin: 0;
    text-align: center;
  }
  input.input {
    min-width: 136px;
    width: 136px;
  }
  .text-helper {
    display: block;
  }
  .flex-child:nth-child(1) {
    legend {
      text-align: right;
      margin-right: 60px;
    }
    .label {
      margin-right: 0.5rem;
    }
    width: auto;
  }
  .flex-child:nth-child(2) {
    max-width: 20%;
    .label {
      @include ut-only-sr;
    }
  }
  .flex-child:nth-child(3) {
    max-width: 20%;
    .label {
      @include ut-only-sr;
    }
  }
  .flex-child:nth-child(4) {
    max-width: 20%;
    .label {
      @include ut-only-sr;
    }
    border-left-width: 0px;
  }
}
