.paginator {
  display: flex;
  list-style-type: none;
  justify-content: center;
  color: $color-gray-1;
  font-size: 13px;

  &__button {
    border: none;
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: $color-gray-1;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    min-width: 32px;
    background: transparent;

    &.selected {
      background-color: $color-yellow-2;
    }

    &:hover {
      background-color: $color-yellow-3;
      cursor: pointer;
    }
  }

  &__dots {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 0px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
  }
}
