/*************************FORMS, LEGENDS & LABELS ***************************** */
.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @include mq-phone {
    flex-direction: column;
  }
  &--vert-center {
    align-items: center;
  }
  &--column {
    flex-direction: column;
  }
  &--space-around {
    justify-content: space-around;
  }
  &--flex-end {
    justify-content: flex-end;
  }
  &--flex-start {
    justify-content: flex-start;
  }
  &--space-between {
    justify-content: space-between;
    @include mq-phone {
      justify-content: flex-start;
    }
  }
  &--align-items-center {
    align-items: center;
  }
  &--align-items-flex-start {
    align-items: flex-start;
  }
  &--align-items-flex-end {
    align-items: flex-end;
  }
  &--table-padding {
    padding: 5px 8px;
  }

  &__flex-child {
    &--flex-end {
      justify-content: flex-end;
    }
    &--fg-1 {
      flex-grow: 1;
    }
  }

  &--two-col-wrap {
    justify-content: space-between;
    @include mq-phone {
      justify-content: flex-start;
    }
    flex-wrap: wrap;
    .flex__child {
      width: calc(50% - 0.5em);
      @include mq-phone {
        width: 100%;
      }
    }
  }

  //  used on the bottom section of the main form
  &--two-col-divider {
    justify-content: space-between;
    @include mq-phone {
      flex-direction: column;
    }
    .flex__child {
      display: flex;
      flex-direction: column;
      @include mq-phone {
        width: 100%;
      }
      &:nth-child(1) {
        padding-right: 3em;
        border-right: 1px solid $color-gray-3;
        @include mq-phone {
          padding: 0;
          border: none;
        }
      }
      &:nth-child(2) {
        padding-left: 1em;
        @include mq-phone {
          padding: 0;
        }
      }
    }
  }
}
