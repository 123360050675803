/************************* TABLES ***************************** */

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border: none;
  table-layout: auto;
  padding: 1em;
  &__caption {
    @include text-light();
    text-transform: uppercase;
    width: 100%;
    padding: 5px 8px;
    color: $color-gray-2;
    margin: 0;
    font-size: 12px;
  }
  &__head {
    background: $color-gray-4;
  }
  &__heading {
    @include text-bold();
    font-size: 12px;
    padding: 5px 8px;
  }
  &__row {
    background: $color-white;
  }
  &__row--zebra {
    @include zebra;
  }
  &__row--highlight {
    border: 1px solid $color-yellow-1;
    background: $color-yellow-2;
    .table__cell {
      @include text-bold();
    }
    > td:last-child {
      position: relative;
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        right: -17px;
        top: -1px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-left: 16px solid $color-yellow-1;
        border-bottom: 20px solid transparent;
        @include mq-phone {
          display: none;
        }
      }
    }
  }
  &__row--expandable {
    text-align: left;
    > td {
      padding-top: 0;
    }
  }
  &__row--bottom-space td.table__cell {
    padding-bottom: 16px;
  }
  &__row--error {
    display: none;
  }
  &__row--error-visible {
    display: table-row;
  }
  &__cell {
    @include text-regular();
    font-size: 12px;
    padding: 12px 8px;
    border: none;
  }
  &__cell--subtotal {
    border-top: 1px solid $color-gray-3;
    color: $color-gray-2;
  }
  &__cell--error {
    padding-top: 0;
    padding-bottom: 18px;
    text-align: left;
    color: $color-red;
  }
}

.table--compact {
  .table__heading {
    padding: 4px 8px;
  }
  .table__cell {
    padding: 5px 8px;
  }
  .table__cell--error {
    padding-top: 0;
    padding-bottom: 12px;
    text-align: right;
    color: $color-red;
  }

  .table__row--highlight > td:last-child:after {
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
  }
}

// Example table code below.

// <table className="table ">
// 	<caption className="table__caption">Caption</caption>
// 	<thead>
// 		<tr className="table__head">
// 			<th className="table__heading">T Head</th>
// 			<th className="table__heading">T Head</th>
// 			<th className="table__heading">T Head</th>
// 			<th className="table__heading">T Head</th>
// 		</tr>
// 	</thead>
// 	<tbody>
// 		<tr className="table__row">
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 		</tr>
// 		<tr className="table__row table__row--highlight">
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 		</tr>
// 		<tr className="table__row">
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 		</tr>
// 		<tr className="table__row table__row--zebra">
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 		</tr>
// 		<tr className="table__row">
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell">T cell</td>
// 			<td className="table__cell table__cell--subtotal">T cell</td>
// 			<td className="table__cell table__cell--subtotal">T cell</td>
// 		</tr>
// 	</tbody>
// </table>
