/************************* TABS ***************************** */

.list-tab-buttons {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  > li {
    display: inline-block;
    margin-right: 5px;
  }
}

// NOTE: style for TAB BUTTONS at top is found in forms partial under buttons section

.list-tab-contents[aria-hidden='true'] {
  display: none;
}
