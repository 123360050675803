body,
html,
#root {
  background-color: $color-gray-5;
  color: $color-gray-1;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.app {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 768px;
  min-width: 1024px;
  width: 100%;
  @include mq-phone {
    min-width: 0;
  }
}

.main {
  margin: $margin-vert-desktop auto;
  max-width: 1024px;
  padding: 0 $margin-horz-desktop;
  width: 100%;
  @include mq-phone {
    margin: $margin-vert-phone auto;
    padding: 0 $margin-horz-phone;
  }
}

// for anything that's a fontAwesome icon
.fa-icon {
  @include fontawesome;
}

.card {
  background-color: $color-white;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
  margin: 0 0 $margin-vert-desktop 0;
  padding: $margin-vert-desktop $margin-horz-desktop;
  @include text-regular();
  &--third {
    max-width: calc(33% - 12px);
    width: calc(33% - 12px);
  }
  &--half {
    max-width: calc(50% - 24px);
  }
  &--two-thirds {
    max-width: calc(67% - 24px);
  }
  &--border-red {
    border-top: 4px solid $color-red;
  }
  &--border-green {
    border-top: 4px solid $color-green;
  }
  &--border-gray {
    border-top: 4px solid $color-gray-3;
  }
  &--erv-totals {
    max-width: calc(54% - 24px);
  }
  @include mq-phone {
    padding: $margin-vert-phone $margin-horz-phone;
    margin: 0 0 $margin-vert-phone 0;
    max-width: none;
    width: 100%;
  }
}

// small dashboard cards
.dashboard-card {
  min-height: 204px;
  &__header {
    text-transform: uppercase;
    text-align: center;
  }
  &__p {
    line-height: 1.5em;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 1em 0;
    color: $color-gray-2;
    font-size: 12px;
    line-height: 1.5em;
  }
  @include mq-phone {
    min-height: 0;
  }
}

// Skip nav. Invisible till user focuses on it .ut-only-sr:focus
.skip-nav {
  position: absolute;
  top: 0.5rem;
  left: 2rem;
  height: auto;
  width: auto;
  overflow: hidden;
  z-index: 1000;
  font-size: 12px;
  color: transparent;
  &:focus {
    color: $color-white;
  }
}

/***************************** BASE TYPOGRAPHY ************************************* */
h1,
.header-1 {
  @include text-light;
  color: $color-blue-2;
  font-size: 1.25em;
  margin-top: 0;
  @include mq-phone {
    font-size: 1em;
  }
}
h2,
.header-2 {
  @include text-light;
  color: $color-blue-2;
  font-size: 1.125em;
  margin-top: 0;
  @include mq-phone {
    font-size: 0.9375em;
  }
}
h3,
.header-3 {
  @include text-bold;
  color: $color-blue-2;
  font-size: 18px;
  margin-top: 0;
}
h4,
.header-4 {
  @include text-medium;
  color: $color-gray-2;
  font-size: 14px;
  margin-top: 0;
}

p,
.paragraph {
  @include text-regular;
  font-size: 13px;
  margin-top: 0;
}

.text-helper {
  @include text-regular;
  font-size: 12px;
  font-style: italic;
  color: $color-gray-2;
  &--no-italics {
    font-style: normal;
  }
}
.text-error {
  color: $color-red;
  font-size: 12px;
  @include text-regular;
}
.caption {
  @include text-light();
  text-transform: uppercase;
  width: 100%;
  padding: 5px 8px;
  color: $color-gray-2;
  margin: 0;
  font-size: 12px;
}

b,
strong {
  @include text-bold;
}
/***************************** LINKS ************************************* */

a,
.a {
  color: $color-blue-2;
  &:hover {
    color: $color-blue-1;
  }
}

/*********************** FONTAWESOME NOTES/ CHEATSHEET ******************************** */

// 	&#xf00d; = X or Times or close icon
// 	&#xf00c; = triangle with exclamation mark in center
// 	&#xf00c; = Check mark
// 	&#xf12a; = Exclamation mark
// 	&#xf023; = locked lock
// 	&#xf12A; = unlocked lock
// 	&#xf128; = question mark
// 	&#xf078; = arrow down (used in header dropdown)
// 	&#xf111; = Large dot for unread messages
//  &#xf053; = arrow going back (paginator)
//  &#xf054; = arrow going forward (paginator)
//  &#xF141; = ellipsis (paginator)
