/************************* MENU DROPDOWN ***************************** */
/* Drop down menus used in header */

.menu-dropdown {
  display: block;
  background: $color-blue-2;
  color: $color-white;
  border-radius: $border-radius;
  padding: 12px 0 12px 0;
  position: absolute;
  top: 52px;
  right: -22px;
  min-width: 240px;
  z-index: 100;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 20%);
  @include text-regular;
  &__backdrop {
    position: fixed;
    z-index: -10;
    //background: pink;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    right: 16px;
    top: -16px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 16px solid $color-blue-2;
  }
  &--help {
    min-width: 360px;
  }
  &--user {
    right: -14px;
    top: 40px;
  }
  &__link-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__link-list-header {
    padding: 12px 24px 4px 24px;
    text-transform: uppercase;
    font-size: 14px;
    @include text-thin;
    &--shortcuts {
      padding-top: 0;
      margin-top: 0;
    }
  }
  &__link-list-item {
    display: inline;
  }
  &__link-list-link,
  .button--menu-dropdown {
    display: block;
    width: 100%;
    text-align: left;
    color: $color-white;
    background: transparent;
    border: none;
    text-decoration: none;
    padding: 12px 24px;
    font-size: 12px;
    margin-bottom: 2px;
    &--short {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 36px;
    }
    &:hover {
      color: $color-white;
      background: $color-blue-1;
    }
    &--disabled,
    &:disabled {
      background: $color-gray-2;
      &:hover {
        background: $color-gray-2;
      }
    }
  }
  &__divider {
    color: $color-white;
    opacity: 0.3;
    margin: 18px auto;
    border-style: solid;
    border-width: 1px;
    &--short {
      width: calc(100% - 48px);
    }
  }
  &__text-divider {
    @include text-thin;
    margin-top: 2em;
    margin-bottom: 4px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 8px 0;
    text-align: center;
    text-transform: uppercase;
  }
  &__paragraph {
    margin: 12px 24px;
    line-height: 1.5em;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      display: block;
      color: $color-white;
      text-decoration: none;
      padding: 0px 24px 6px 24px;
      font-size: 12px;
    }
  }
  &__list--shortcuts {
    > li {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 36px;
    }
  }
  &__input {
    padding: 0px 24px;
    display: block;
  }
  &__input-label {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  &__input-element {
    width: 200px;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0;
  }
}

.button--menu-dropdown.button--needs-ervs:not(:disabled) {
  padding-left: 36px;
  position: relative;
  &:before {
    @include fontawesome;
    font-size: 13px;
    content: '\f06a';
    position: absolute;
    top: 12px;
    left: 20px;
  }
}

.button--menu-dropdown-hospital:disabled {
  padding-left: 36px;
  position: relative;
  &:before {
    @include fontawesome;
    font-size: 1em;
    content: '\f00c';
    position: absolute;
    top: 12px;
    left: 20px;
  }
}
